import React from "react";
import MainContent from "../MainContent";
import "../tail.css";
function MainDoc() {
  return (
    <div>
      <MainContent />
    </div>
  );
}

export default MainDoc;
